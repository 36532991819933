import { useApiUrl, serviceEnv} from '@/config/url.js';
import CommonConfig from '@/config/common.js';
import SystemConfig from '@/config/system.js';

let siteName;
let globalData = {
  accessToken: ''
};
switch (serviceEnv) {
case 'local':
  siteName = '微爱服务配置(本地)';
  globalData.accessToken = 'tTAQ8FnmfjKCweM4cmdQraY0vSQlKigGeKJGM91WENcmvHOurYuZLVfWbWL1OeUOq24kVTwW9fYBGGUpRfAEMPoO6vulQnLtUbyE';

  break;
case 'weloveTest':
  siteName = '微爱服务配置(测试)';
  break;
case 'welove':
  siteName = '微爱服务配置(正式)';
  break;
case 'sweetTest':
  siteName = '情侣空间服务配置(测试)';
  break;
case 'sweet':
  siteName = '情侣空间服务配置(正式)';
  break;
case 'sweetusTest':
  siteName = 'sweetus(测试)';
  break;
case 'sweetus':
  siteName = 'sweetus(正式)';
  break;
case 'cubbilyTest':
  siteName = 'cubbily(测试)';
  break;
case 'cubbily':
  siteName = 'cubbily(正式)';
  break;
default:
  break;
}

export default {
  ...CommonConfig,
  ...SystemConfig,
  apiUrl: useApiUrl,
  serviceEnv,
  corporation: '',
  siteName,
  globalData
};
